import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import HeaderButton from "../components/HeaderButton";
import YouTubeEmbed from "../components/YouTubeEmbed";
import BottomAction from "../sections/home3/BottomAction";

const Testimonials = ({ data }) => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: <HeaderButton />,
          footerStyle: "style3",
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>UStrive | Testimonials</title>
          <link rel="canonical" href="https://ustrive.com/testimonials" />
        </Helmet>
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">UStrive Testimonials</h2>
                  <p className="font-size-7 mb-7">
                    Hear from our past &amp; present students about their
                    UStrive experience!
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {data.allContentfulTestimonials.edges.map(
                (testimonial, index) => (
                  <YouTubeEmbed
                    key={testimonial.node.youtube}
                    id={testimonial.node.youtube}
                    autoplay={index === 0}
                  />
                )
              )}
            </div>
          </div>
        </div>
        <BottomAction />
      </PageWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query testimonials {
    allContentfulTestimonials {
      edges {
        node {
          id
          createdAt(fromNow: true)
          title
          youtube
        }
      }
    }
  }
`;

export default Testimonials;
