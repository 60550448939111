import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const YouTubeEmbed = ({ id, autoplay }) => {
  return (
    <div className="col-lg-6 col-md-10" key={id}>
      <VideoContainer>
        <iframe
          // width="853"
          // height="480"
          width="560"
          height="300"
          src={`https://www.youtube.com/embed/${id}?autoplay=${
            autoplay ? 1 : 0
          }`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="UStrive Testimonial"
        />
      </VideoContainer>
    </div>
  );
};

export default YouTubeEmbed;
